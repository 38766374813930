import React from 'react'
import { connect } from 'react-redux'
import Layout from '../../components/layout'
import SEO from '../../components/seo'

function Imprint({ translations }) {
    return (
        <Layout>
            <SEO title={translations.legal_notice_title} description={translations.legal_notice_meta_description} />
            <div className="subpage plain-text-content">
                <div className="lightholder"></div>
                <section>
                    <div className="inner-wrapper mb-2">
                        <h1>
                            Site notice
                        </h1>
                        <p className="headline yellow mt-3 mb">
                            Information provided according to Sec. 5 German Telemedia Act (TMG)
                        </p>
                        BODYSHAKE® GmbH<br />
                        Brauereistr. 45 <br />
                        08064 Zwickau <br /><br />
                        Registered office: Amtsgericht Chemnitz <br />
                        Company registration number: HRB 33348 <br /><br />
                        <p className="headline yellow mt-3 mb">
                            Represented by
                        </p>
                        the Managing Directors Steve Hachenberger and Daniel Ratzow
                        <p className="headline yellow mt-3 mb">
                            Contact
                        </p>
                        <span>Phone: <a href="tel:+4937569245150" target="_blank" rel="noreferrer noopener">+49 375 692 451 50</a></span> <br />
                        <span>Email: <a href="&#109;&#97;&#105;&#108;&#116;&#111;&#58;&#105;&#110;&#102;&#111;&#64;&#98;&#111;&#100;&#121;&#115;&#104;&#97;&#107;&#101;&#46;&#99;&#111;&#109;">&#105;&#110;&#102;&#111;&#64;&#98;&#111;&#100;&#121;&#115;&#104;&#97;&#107;&#101;&#46;&#99;&#111;&#109;</a></span>
                        <p className="headline yellow mt-3 mb">
                            VAT
                        </p>
                        VAT Id number according to Sec. 27 a German Value Added Tax Act: <br />
                        DE815878267
                        <p className="headline yellow mt-3 mb">
                            Design und Realization
                        </p>
                        Ö-Konzept GmbH &amp; Co. KG <br />
                        Audistr. 3 <br />
                        08058 Zwickau <br /><br />

                        <p className="headline yellow mt-3 mb">
                            Online dispute resolution
                        </p>

                        <span>Online dispute resolution under EU Regulation No. 524/2013: The European Commission has provided an online platform to facilitate the resolution of online disputes. It can be found <a href="https://ec.europa.eu/consumers/odr/" target="_blank" rel="noreferrer">here</a>. We prefer to solve your requests in direct contact with you and therefore do not participate in alternative consumer dispute resolution proceedings. To contact us please use the following mail address: <a href="mailto:info@bodyshake.com" target="_blank" rel="noreferrer">info@bodyshake.com</a></span>
                        <p className="headline yellow mt-3 mb">
                            Social networks
                        </p>
                        This site notice also applies to the following pages and accounts in social networks:
                        <ul>
                            <li>Facebook-Page "Bodyshake" (<a href="https://www.facebook.com/bodyshakeofficial" target="_blank" rel="noreferrer noopener">https://www.facebook.com/bodyshakeofficial</a>)</li>
                            <li>Twitter-Account "Bodyshake" (<a href="https://www.twitter.com/bodyshake_com" target="_blank" rel="noreferrer noopener">https://www.twitter.com/bodyshake_com</a>)</li>
                            <li>Instagram-Account “bodyshakeofficial” (<a href="https://www.instagram.com/bodyshakeofficial" target="_blank" rel="noreferrer noopener">https://www.instagram.com/bodyshakeofficial</a>)</li>
                            <li>YouTube-Channel “BodyShake” (<a href="https://www.youtube.com/@bodyshakeoffical" target="_blank" rel="noreferrer noopener">https://www.youtube.com/@bodyshakeoffical</a>)</li>
                            <li>LinkedIn-Page “BODYSHAKE” (<a href="https://www.linkedin.com/company/bodyshake" target="_blank" rel="noreferrer noopener">https://www.linkedin.com/company/bodyshake</a>)</li>
                            <li>TikTok-Account »bodyshakeofficial« (<a href="https://www.tiktok.com/@bodyshakeofficial" target="_blank" rel="noreferrer noopener">https://www.tiktok.com/@bodyshakeofficial</a>)</li>
                        </ul>
                    </div>
                </section>
            </div>
        </Layout>
    )
}

const mapStateToProps = state => ({
    translations: state.preferences.translations
})

export default connect(mapStateToProps)(Imprint)
